<template>
  <div class="login-page">
    <div class="opca-mask">
      <div class="login-left-text">
        <p>Welcome！</p>
        <p>阿海和小雯雯的秘密学习基地</p>
      </div>
      <div class="login-form">
        <a-form-model ref="loginForm" :model="loginParams" :rules="loginRules" class="form-content">
          <p class="title_375">阿海和小雯雯的秘密学习基地</p>
          <a-form-model-item prop="tel" style="margin-bottom: 60px">
            <a-input v-model="loginParams.phone" size="large" type="text" placeholder="请输入账号">
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input-password v-model="loginParams.password" size="large" placeholder="请输入密码" :visibilityToggle="false">
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input-password>
          </a-form-model-item>
          <a-form-model-item>
            <a-checkbox :checked="checked" @change="forget">记住密码</a-checkbox>
          </a-form-model-item>
          <a-button @click="login" :loading="logining" class="login-btn" type="primary" size="large"
            style="margin-top: 100px">登录</a-button>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logining: false,
      checked: false,
      loginParams: {
        phone: '',
        password: '',
      },
      loginForm: this.$form.createForm(this, { name: 'loginForm' }),
      loginRules: {
        phone: [{ required: true, message: '填写账号', trigger: 'blur' }],
        password: [{ required: true, message: '填写密码', trigger: 'blur' }],
      },
    }
  },
  created() {
    if (localStorage.getItem('pw')) {
      this.checked = true
      this.loginParams = JSON.parse(localStorage.getItem('pw'))
    }
  },
  methods: {
    forget(e) {
      this.checked = e.target.checked
    },
    login() {
      let t = this
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.checked) {
            localStorage.setItem('pw', JSON.stringify(this.loginParams))
          } else {
            localStorage.setItem('pw', '')
          }
          t.logining = true
          t.$store
            .dispatch('login', t.loginParams)
            .then((res) => {
              let { code, data, msg } = res
              console.log(res)
              if (code == 0) {
                t.$message.success('登录成功', 1)
                // // 判断是否是移动端
                // const userAgent = navigator.userAgent
                // // 匹配设备型号信息
                // const modelRegex =
                //   /(Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini)/

                // const match = userAgent.match(modelRegex)
                // if (match) {
                //   t.$router.push({ path: '/h5' })
                // } else {
                //   }
                t.$router.push({ path: '/index' })
              } else {
                t.$message.error(msg, 1)
              }
              t.logining = false
            })
            .catch((err) => {
              t.logining = false
            })
        }
      })
    },
  },
}
</script>

<style lang="less">
@w: 19.2;
@h: 10.8;

.login-page {
  width: 100vw;
  height: 100vh;
  background: url(https://jdhealth.oss-cn-hangzhou.aliyuncs.com/login_bg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;

  .a-logo-box {
    margin: 0 auto;
    padding: 100px 0 50px;

    img {
      width: 200px;
      display: block;
      height: auto;
      margin: 0 auto;
    }

    h1 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .opca-mask {
    width: 1517vw / @w;
    height: 670vh / @h;
    margin-left: 59vw / @w;
    margin-top: 186vh / @h;
    background: linear-gradient(144deg,
        rgba(217, 229, 237, 0.37) 0%,
        rgba(0, 99, 238, 0.37) 100%);
    display: flex;
  }

  .login-left-text {
    width: 901vw / @w;
    font-size: 98vh / @h;
    line-height: 137vh / @h;
    color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50vw / @w, 50vh / @h);

    p {
      margin-bottom: 0;
    }
  }

  .login-form {
    width: 616vw / @w;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;

    .title_375 {
      display: none;
    }
  }

  .form-content {
    width: 400vw / @w;
    margin-top: 160vh / @h;

    .ant-input {
      border: none;
      border-bottom: 1px solid #d9d9d9;
      background-color: #f9f9f9;
    }
  }

  .login-btn {
    width: 100%;
    border-radius: 20px;
  }
}

// @media screen and (min-width: 300px) and (max-width: 480px) {
//   //   html {
//   //     min-width: 480px;
//   //     // transform: scale(0.38);
//   //     transform-origin: 0 0;
//   //     transition: all 0.3s;
//   //   }
//   .login-page {
//     width: 100%;
//     height: 100%;
//     .opca-mask {
//       width: 100%;
//       margin-left: 5%;
//     }
//     .login-form {
//       width: 90%;
//       background-color: #f9f9f9;
//       display: flex;
//       justify-content: center;

//       .title_375 {
//         display: block;
//         text-align: center;
//         font-size: 23px;
//         font-weight: bold;
//         margin-bottom: 80px;
//       }
//       .form-content {
//         width: 90%;
//         margin-top: 20px;
//       }
//     }
//     .login-left-text {
//       //   display: none;
//       //   width: 20% !important;
//       font-size: 16px;
//       // line-height: 20%;
//       color: #f9f9f9;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       display: none;
//       .form-content {
//         width: 90%;
//       }
//       // transform: translate(-50vw / @w, 50vh / @h);
//       p {
//         margin-bottom: 0;
//       }
//     }
//   }
// }
</style>
